@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/typographies";

.DayEntry {
  margin-top: 8px;
  padding: 16px;
  background-color: $milk;
  align-items: flex-start !important;

  .top-row {
    justify-content: space-between;
    .day-title {
      @include heading-h5-20;
      color: $black;
    }
  }

  .switch-description {
    @include caption-medium-12;
    color: $grey;
    padding-bottom: 16px;
    border-bottom: 1px dashed $greyLight;

    .bold-text {
      @include caption-bold-12;
    }
  }

  .time-container {
    margin-top: 16px;
    justify-content: flex-start !important;

    .time-seperator {
      margin: 18px 8px 0;
      @include body-regular-16;
    }

    .MuiTextField-root {
      .MuiFormLabel-root {
        @include caption-medium-12;
        color: $blackLight;
      }
      .MuiInputBase-root {
        @include body-regular-14;
        color: $black;

        width: 120px;
        height: 48px;
        border: 1px solid $greyLight;
        border-radius: 8px;
        padding: 0 16px 0 8px;
        margin-top: 20px;
      }
      .MuiInput-underline::before,
      .MuiInput-underline::after {
        border: 0;
      }
    }
  }
}
