@import "./assets/styles/colors";
@import "./assets//styles/styles.scss";

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"), url("./assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"), url("./assets/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"), url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inconsolata-Regular";
  src: local("Inconsolata-Regular"), url("./assets/fonts/Inconsolata-Regular.ttf");
}
@font-face {
  font-family: "Inconsolata-Bold";
  src: local("Inconsolata-Bold"), url("./assets/fonts/Inconsolata-Bold.ttf");
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.App {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .content {
    width: 100%;
    height: 100%;
    margin-bottom: 58px;
    overflow-x: hidden;
  }
  .transition-group {
    height: 100%;
  }
}
