.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.touchable-opacity,
.touchable-highlight {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  transition-duration: 0.5s;
}

.touchable-opacity:active {
  opacity: 0.7;
}
.touchable-highlight:active {
  filter: brightness(85%);
}

.modal-dialog {
  .MuiDialog-paper {
    width: 100%;
    margin: 0 16px;
    background-color: $milk;
    text-align: center;
    border-radius: 8px;
    .close-button {
      align-self: flex-end;
      padding: 16px;
      font-size: 16px;
      margin-bottom: -40px;
    }
    .title {
      margin-top: 24px;
      color: $black;
      font-size: 16px;
      font-family: "Inter-Bold";
    }
    .description {
      margin-top: 8px;
      color: $blackLight;
      font-size: 12px;
      line-height: 18px;
      font-family: "Inter-Regular";
      padding: 0 16px;
    }
    .description-bold {
      font-family: "Inter-Bold";
    }
    .button-container {
      margin-top: 24px;
      height: 48px;
      .dialog-button {
        flex: 1;
        height: 100%;
        box-shadow: none;
        border-radius: 0;
        font-family: "Inter-Bold";
        font-size: 14px;
        text-transform: none;
      }
      .dialog-left {
        background-color: $greyLighter;
        color: $sauce;
      }
      .dialog-right {
        background-color: $sauce;
        color: $milk;
      }
      .dialog-disabled {
        background-color: $grey;
        color: $milk;
      }
    }
  }
}

.loading-dialog {
  .MuiDialog-paper {
    width: 100px;
    padding: 24px;
  }
}
